
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCompanyModal from "@/components/modals/forms/AddCompanyModal.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { ActionsFi } from "@/store/enums/StoreFiEnums";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddCompanyModal,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const searchTearm = ref("");

    interface WIJournal {
      ledger_id: string;
      ledger_name: string;
      ledger_type: string;
      ledger_group: string;
      opening_balance: string;
      closing_balance: string;
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };

    var tableData = ref<Array<WIJournal>>([]);

    const getCompanyList = async (data) => {
      loadingData.value = true;

      try {
        var values = {
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          // records_per_page: 1000,
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_LEDGER_LIST, values)
          .then((data) => {
            debugger;
            tableData.value = [];
            const totalCount = data.data.total_records;
            // set pagination
            paginationData.value["total"] = data?.data?.total_records;
            paginationData.value["start"] = data?.data?.records_from;
            paginationData.value["end"] = data?.data?.records_upto;
            paginationData.value["activePage"] = data?.data?.page;
            paginationData.value["totPage"] = data?.data?.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data?.data?.bar_range_start;
              index < data?.data?.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination
            data = data?.data;
            var resultsM = ref<Array<WIJournal>>([]);

            for (let j = 0; j < data.result_list.length; j++) {
              resultsM.value = Array({
                ledger_id: data.result_list[j]["ledger_id"],
                ledger_name: data.result_list[j]["ledger_name"],
                ledger_type: data.result_list[j]["ledger_type"],
                ledger_group: data.result_list[j]["ledger_group"],
                opening_balance: data.result_list[j]["opening_balance"],
                closing_balance: data.result_list[j]["closing_balance"],
              });
              console.log("resultsm:" + resultsM.value);
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    const initCustomers = ref<Array<WIJournal>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Ledger", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
    };


    return {
      tableData,
      search,
      searchItems,
      checkedCompany,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
    };
  },
});
